<script lang="ts">
    import { Input } from '$lib/components/ui/input';
    import { Label } from '$lib/components/ui/label';
    import { Button } from '$lib/components/ui/button';
    import { Separator } from '$lib/components/ui/separator';
    import { inertia } from '@inertiajs/svelte';
    import axios from 'axios';
    import { Turnstile } from 'svelte-turnstile';
    import { onMount } from 'svelte';
    import { getTheme } from '@/utility/theming/darkmode';
    import { Checkbox } from '$lib/components/ui/checkbox';
    import toast from 'svelte-french-toast';
    import { animateStars, createStarGrid } from '@/utility/stars';

    interface Refferal {
        code: string;
        name: string;
    }

    const SITE_KEY: string = '0x4AAAAAAAIbV5smWueSWBbM';
    let turnstileToken: string = '';
    let creatingUser: boolean = false;
    let referrals: Refferal[] = [];

    let userData = {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        referral: '',
        terms: false,
    };

    onMount(() => {
        const starsContainer: HTMLElement | null = document.getElementById('stars-container');
        if (starsContainer) {
            createStarGrid(starsContainer);
            setInterval(animateStars, 100);
        }
    });

    function handleTurnstileCallback(event: CustomEvent<{ token: string }>): void {
        turnstileToken = event.detail.token;
    }

    function resetTurnstileToken(): void {
        turnstileToken = '';
    }

    async function handleSubmit(event: Event): Promise<void> {
        event.preventDefault();

        if (!userData.password || !userData.password_confirmation) {
            toast.error('Both password fields are required');
            return;
        }

        if (userData.password !== userData.password_confirmation) {
            toast.error('Passwords do not match');
            return;
        }

        if (!userData.terms) {
            toast.error('Please agree to our Terms and Conditions');
            return;
        }

        if (!turnstileToken) {
            toast.error('Please complete the Turnstile verification');
            return;
        }

        try {
            creatingUser = true;
            const response = await axios.post('/register-post', {
                ...userData,
                turnstileToken,
            });

            if (response.status === 200) {
                localStorage.setItem('userData', JSON.stringify(response.data.userData));
                toast.success('Registration successful');
                window.location.href = '/feed';
            }
        } catch (error: any) {
            const message = error.response?.data?.message || 'Registration failed';
            toast.error(message);
        } finally {
            creatingUser = false;
        }
    }
</script>

<div class="flex min-h-screen items-center justify-center py-5">
    <div id="stars-container" class="absolute inset-0"></div>

    <div class="w-full max-w-lg space-y-6 rounded-lg bg-white p-6 shadow-xl dark:bg-gray-800">
        <div class="text-center">
            <a href="/" class="inline-block">
                <img src="/img/logo-white.png" alt="Logo" class="mx-auto h-12" />
            </a>
        </div>

        <form on:submit="{handleSubmit}" class="space-y-4">
            <div class="space-y-2">
                <Label for="name">Name</Label>
                <Input type="text" id="name" bind:value="{userData.name}" required placeholder="Enter your name" />
            </div>

            <div class="space-y-2">
                <Label for="email">Email</Label>
                <Input type="email" id="email" bind:value="{userData.email}" required placeholder="Enter your email" />
            </div>

            <div class="space-y-2">
                <Label for="password">Password</Label>
                <Input
                    type="password"
                    id="password"
                    bind:value="{userData.password}"
                    required
                    placeholder="Create password"
                />
            </div>

            <div class="space-y-2">
                <Label for="password_confirmation">Confirm Password</Label>
                <Input
                    type="password"
                    id="password_confirmation"
                    bind:value="{userData.password_confirmation}"
                    required
                    placeholder="Confirm password"
                />
            </div>

            {#if referrals.length > 0}
                <div class="space-y-2">
                    <Label for="referral">Referral (Optional)</Label>
                    <select
                        id="referral"
                        bind:value="{userData.referral}"
                        class="w-full rounded-md border p-2 dark:border-gray-600 dark:bg-gray-700"
                    >
                        <option value="">Select referral</option>
                        {#each referrals as referral}
                            <option value="{referral.code}">{referral.name}</option>
                        {/each}
                    </select>
                </div>
            {/if}

            <div class="flex items-center space-x-2">
                <Checkbox bind:checked="{userData.terms}" id="terms" />
                <Label for="terms" class="text-sm">
                    I agree to the
                    <a href="/terms" use:inertia class="text-blue-500 underline">Terms</a>
                    and
                    <a href="/privacy" use:inertia class="text-blue-500 underline">Privacy Policy</a>
                </Label>
            </div>

            <div class="my-3 h-[72px]">
                <Turnstile
                    siteKey="{SITE_KEY}"
                    theme="{getTheme()}"
                    on:turnstile-callback="{handleTurnstileCallback}"
                    on:turnstile-expired="{resetTurnstileToken}"
                    on:turnstile-timeout="{resetTurnstileToken}"
                    on:turnstile-error="{resetTurnstileToken}"
                />
                <!-- <div class="z-10 size-full animate-pulse rounded-lg bg-gray-300 dark:bg-white/10"></div> -->
            </div>

            <Button
                type="submit"
                class="w-full"
                disabled="{creatingUser ||
                    !userData.name ||
                    !userData.email ||
                    !userData.password ||
                    !turnstileToken ||
                    !userData.terms}"
            >
                {creatingUser ? 'Creating Account...' : 'Create Account'}
            </Button>

            <Separator />

            <p class="text-center text-sm">
                Already have an account?
                <a href="/login" use:inertia class="text-blue-500 hover:underline"> Sign in </a>
            </p>
        </form>
    </div>
</div>
